import React from 'react'
// import { NavLink } from 'react-router-dom'
const About = () => {
  return (
    <div>
      <span>hai. .</span>
    </div>
  )
}
 

export default About
